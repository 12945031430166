import { FC } from "react";
import styled from "styled-components";
import usePageTitle from "../../hooks/usePageTitle";
import usePageDesciption from "../../hooks/usePageDescription";
import { useTranslation } from "react-i18next";
import GetStartedCTA from "../../Components/GetStartedCTA";
import ContentContainer from "../../Components/ContentContainer";
import ScrollableList from "../../Components/ScollableList";
import { CONSULTING_HELP_LIST } from "../../shared/constants";

const ProcessHeading = styled.h3`
    font-size: 2em;
    margin-bottom: 0.66em;
`;
const StyledParagraph = styled.p`
    font-size: 1.1em;
    font-weight: 500;
    color: #aaa;
`;
const ProcessSection = styled.section`
    // background: #333;
    padding: 5em 0em;
    @media screen and (min-width:768px){
        padding-right: 15vw;

    }
`;
const HeadingNumber = styled.span`
    color: blue;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #4284fb, #00e7ff);
    background-clip: text;
    -webkit-background-clip: text;

`;
const HeroContainer = styled.div`
    max-height: 80vh;
    min-height: 60vh;
    overflow: hidden;
    padding: 2em 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const StyledHeading = styled.h1`
    padding-bottom: 0.33em;
    overflow-wrap: break-word;
    width: 100%;
`;
const StyledSubheading = styled.p`
    font-size: 1.33em;
    font-weight: 600;
    color: #aaa;
    min-width: 100%;
`;
const StyledProcessHeading = styled.h2`
    padding-bottom: 1em;
`;

const Consulting: FC = () => {
    const {t} = useTranslation();
    usePageTitle(`Technical Consulting Services`);
    usePageDesciption("Get help navigating your most complex problems. We deliver cloud, software, systems, and architecture solutions and help our clients break through limitations.")
    return(
        <>  
            <HeroContainer>
                <StyledHeading>{t("consultingPageHeading")}</StyledHeading>
                <StyledSubheading>Simple solutions to complex technical problems. From architecture design, to prototyping and advanced networking solutions, we can help you break through your toughest technical challenges.</StyledSubheading>
            </HeroContainer>
            <ScrollableList
                list={CONSULTING_HELP_LIST}
            />
            <ProcessSection>
                <ContentContainer>
                    <StyledProcessHeading>How it works</StyledProcessHeading>
                    <ProcessHeading><HeadingNumber>01</HeadingNumber> Discovery</ProcessHeading>
                    <StyledParagraph>Our discovery process starts with a deep dive into your business to gain insight into your software architecture, systems, digital strategy, and organizational goals.</StyledParagraph>
                    <br />
                    <ProcessHeading><HeadingNumber>02</HeadingNumber> Analysis</ProcessHeading>
                    <StyledParagraph>The insights and knowledge gained through the discovery process sets the stage for us to develop solutions that align with your organization's goals.</StyledParagraph>
                    <br />
                    <ProcessHeading><HeadingNumber>03</HeadingNumber> Solutions Delivered</ProcessHeading>
                    <StyledParagraph>Finally, we'll deliver you solutions, and an implementation plan, that best meets your organization's goals. If required, we can extend our help through the implementation and development process.</StyledParagraph>
                </ContentContainer>
            </ProcessSection>
            <GetStartedCTA />
        </>
    )
}

export default Consulting;