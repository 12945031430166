import { FC } from "react";
import styled from "styled-components";
import usePageTitle from "../../hooks/usePageTitle";
import { CLOUD_HELP_LIST } from "../../shared/constants";
import usePageDesciption from "../../hooks/usePageDescription";
import { useTranslation } from "react-i18next";
import ScrollableList from "../../Components/ScollableList";
import GetStartedCTA from "../../Components/GetStartedCTA"

const HeroContainer = styled.div`
    max-height: 80vh;
    min-height: 60vh;
    overflow: hidden;
    padding: 2em 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const StyledHeading = styled.h1`
    padding-bottom: 0.33em;
    overflow-wrap: break-word;
    width: 100%;
`;
const StyledSubheading = styled.p`
    font-size: 1.33em;
    font-weight: 600;
    color: #aaa;
`;

const CloudDevelopment: FC = () => {
    const {t} = useTranslation();
    usePageTitle(`Cloud Development Services`);
    usePageDesciption("Get highly available and well architected cloud solutions developed. Best in class, secure cloud solutions backed by IaC.");
    return(
        <>
            <HeroContainer>
                <StyledHeading>{t("cloudPageHeading")}</StyledHeading>
                <StyledSubheading>Well architected, highly available, and secure cloud solutions built for leading cloud providers including AWS, GCP, and Microsoft Azure.</StyledSubheading>
            </HeroContainer>
            <ScrollableList
                list={CLOUD_HELP_LIST}
            />
            <GetStartedCTA />
        </>
    )
}

export default CloudDevelopment;